import React from "react";

function LabelCom({
  label = "",
  labelOn = "top",
  error = false,
  errorLabel = null,
  labelClassName,
  between = false,
  disabled = false,
  children,
  divClassName = "",
  required = false
}) {
  return (
    <div className="">
      <div
        className={` flex ${
          ["right", "left"].includes(labelOn) ? "items-center" : "flex-col"
        } gap-[0px] ${between ? "justify-between max-w-[350px]" : ""} ${
          divClassName ? divClassName : "p-[12px]"
        }`}
      >
        {label && ["left", "top"].includes(labelOn) ? (
          <label
            className={`${
              ["left", "right"].includes(labelOn) ? "m-[5px_10px_0px_10px]" : "mb-[5px]"
            } text-[16px] text-nowrap ${labelClassName} ${
              disabled ? "text-gray-600" : ""
            }`}
          >
            {label} <span className="text-red-500">{required && '*'}</span>
          </label>
        ) : null}
        <div className={`w-full`}>
          <div className={`w-full ${error ? "border-border-red-500" : ""}`}>
            {children}
          </div>
          {error && (
            <div className="w-full ">
              <label className="text-[14px]  text-red-500">{errorLabel}</label>
            </div>
          )}
        </div>
        {label && ["right", "bottom"].includes(labelOn) ? (
          <label
            className={`m-[5px_10px] text-[16px] text-nowrap ${labelClassName} ${
              disabled ? "text-gray-600" : ""
            }`}
          >
            {label}{required && '*'}
          </label>
        ) : null}
      </div>
    </div>
  );
}

export default LabelCom;
