import {
  businessTripApprove,
  businessTripDownloadCertificate,
  cashDownloadDocument4,
  cashDownloadRKO,
  getBusinessTripCahs,
  getBusinessTripCahsFileById,
  getCashAccountPlan,
  getCashArrivalOrder,
  getCashBook,
  getCashSER,
  getCashDocument4ApprovedOnes,
  getCashMemorialWarrant1,
  getCashMemorialWarrant8,
  getUpdateAgreements,
  postCashAccountPlan,
  postCashArrivalOrder,
  postCashDocument4,
  postUpdateAgreements,
  updateBusinessTripCahsApprove,
  putCashSER,
  getApprovedSER,
  getRejectedSER,
  getWarehouseByNumberName,
  getWarehouseDeffectsByStaffIdOrNameId,
  postAccountingAgreementsData,
  getIncomingByWnumCid,
  postIncoming,
  getAccountingOutgoingData,
  postAccountingOutgoingData,
  getAccountingOutgoingSearch,
  postWarehouseStat,
  getWarehouseDisposal,
  postBid,
} from "../../globalStateQueries/AccountingQueries";

export const AccountingExtraReducers = (builder) => {
  // businessTrip download certificate
  builder
    .addCase(businessTripDownloadCertificate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(businessTripDownloadCertificate.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(businessTripDownloadCertificate.rejected, (state, action) => {
      state.loading = false;
    });

  // businessTrip approve
  builder
    .addCase(businessTripApprove.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(businessTripApprove.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(businessTripApprove.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash salary earley receipt
  builder
    .addCase(getCashSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashSER.fulfilled, (state, action) => {
      state.loading = false;
      state.cashSER = action.payload;
    })
    .addCase(getCashSER.rejected, (state, action) => {
      state.loading = false;
    });

  // put cash salary earley receipt
  builder
    .addCase(putCashSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(putCashSER.fulfilled, (state, action) => {
      state.loading = false;
      // state.cashSalaryEarlyReceipt = action.payload;
    })
    .addCase(putCashSER.rejected, (state, action) => {
      state.loading = false;
    });

  // get approved salary early recipt
  builder
    .addCase(getApprovedSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getApprovedSER.fulfilled, (state, action) => {
      state.loading = false;
      state.approvedSER = action.payload;
    })
    .addCase(getApprovedSER.rejected, (state, action) => {
      state.loading = false;
    });

  // get rejected salary early recipt
  builder
    .addCase(getRejectedSER.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRejectedSER.fulfilled, (state, action) => {
      state.loading = false;
      state.rejectedSER = action.payload;
    })
    .addCase(getRejectedSER.rejected, (state, action) => {
      state.loading = false;
    });

  // post cash document 4
  builder
    .addCase(postCashDocument4.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postCashDocument4.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCashDocument4.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash document 4 approved ones
  builder
    .addCase(getCashDocument4ApprovedOnes.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashDocument4ApprovedOnes.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashDocument4ApprovedOnes.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash book
  builder
    .addCase(getCashBook.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashBook.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashBook.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash memorial warrant1
  builder
    .addCase(getCashMemorialWarrant1.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashMemorialWarrant1.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashMemorialWarrant1.rejected, (state, action) => {
      state.loading = false;
    });

  // cash download document 4
  builder
    .addCase(cashDownloadDocument4.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(cashDownloadDocument4.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(cashDownloadDocument4.rejected, (state, action) => {
      state.loading = false;
    });

  // cash download rko
  builder
    .addCase(cashDownloadRKO.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(cashDownloadRKO.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(cashDownloadRKO.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash account plan
  builder
    .addCase(getCashAccountPlan.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashAccountPlan.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashAccountPlan.rejected, (state, action) => {
      state.loading = false;
    });

  // post cash account plan
  builder
    .addCase(postCashAccountPlan.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postCashAccountPlan.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCashAccountPlan.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash memorial warrant8
  builder
    .addCase(getCashMemorialWarrant8.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashMemorialWarrant8.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashMemorialWarrant8.rejected, (state, action) => {
      state.loading = false;
    });

  // post cash arrival_order
  builder
    .addCase(postCashArrivalOrder.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postCashArrivalOrder.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postCashArrivalOrder.rejected, (state, action) => {
      state.loading = false;
    });

  // get cash arrival_order
  builder
    .addCase(getCashArrivalOrder.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCashArrivalOrder.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getCashArrivalOrder.rejected, (state, action) => {
      state.loading = false;
    });

  // post postUpdateAgreements
  builder.addCase(postUpdateAgreements.pending, (state) => {
    state.loading = true;
  });

  builder
    .addCase(postUpdateAgreements.fulfilled, (state, action) => {
      state.loading = false;
      state.agreements = action.payload;
    })
    .addCase(postUpdateAgreements.rejected, (state, action) => {
      state.loading = false;
    });

  // get getUpdateAgreements
  builder
    .addCase(getUpdateAgreements.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getUpdateAgreements.fulfilled, (state, action) => {
      state.loading = false;
      state.accountingAgreementsData = action.payload;
    })
    .addCase(getUpdateAgreements.rejected, (state, action) => {
      state.loading = false;
    });
  // getBusinessTripCahs

  builder
    .addCase(getBusinessTripCahs.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getBusinessTripCahs.fulfilled, (state, action) => {
      state.BusinessTripCahs = action.payload;
      state.loading = false;
    })
    .addCase(getBusinessTripCahs.rejected, (state, action) => {
      console.error("Failed to fetch business trip data:", action.payload);
    });

  // getWarehouseDeffectsByStaffIdOrNameId
  builder
    .addCase(getWarehouseDeffectsByStaffIdOrNameId.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getWarehouseDeffectsByStaffIdOrNameId.fulfilled, (state, action) => {
      state.staffWarehouseDeffects = action.payload;
      state.loading = false;
    })
    .addCase(getWarehouseDeffectsByStaffIdOrNameId.rejected, (state, action) => {
      console.error("Failed to fetch business trip data:", action.payload);
      state.loading = false;
    });
  // postAccountingAgreementsData
  builder
    .addCase(postAccountingAgreementsData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postAccountingAgreementsData.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postAccountingAgreementsData.rejected, (state, action) => {
      state.loading = false;
    });

  // getAccountingOutgoingData
  builder
    .addCase(getAccountingOutgoingData.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAccountingOutgoingData.fulfilled, (state, action) => {
      state.loading = false;
      state.accountingOutgoingData = action.payload;
    })
    .addCase(getAccountingOutgoingData.rejected, (state, action) => {
      state.accountingOutgoingData = [];
      state.loading = false;
    });
  // getAccountingOutgoingSearch
  builder
    .addCase(getAccountingOutgoingSearch.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getAccountingOutgoingSearch.fulfilled, (state, action) => {
      state.accountingOutgoingSearch = action.payload;
      state.loading = false;
    })
    .addCase(getAccountingOutgoingSearch.rejected, (state, action) => {
      state.accountingOutgoingSearch = 0;
      console.log("Failed to fetch business trip data:", action.payload);
      state.loading = false;
    });
  // postAccountingOutgoingData
  builder
    .addCase(postAccountingOutgoingData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(postAccountingOutgoingData.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(postAccountingOutgoingData.rejected, (state, action) => {
      state.loading = false;
    });

  // getBusinessTripCahsFileById
  builder
    .addCase(getBusinessTripCahsFileById.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getBusinessTripCahsFileById.fulfilled, (state, action) => {
      state.getBusinessTripCahsFileById = action.payload;
      state.loading = false;
    })
    .addCase(getBusinessTripCahsFileById.rejected, (state, action) => {
      console.error("Failed to fetch business trip data:", action.payload);
    });
  // Approve
  builder
    .addCase(updateBusinessTripCahsApprove.fulfilled, (state, action) => {
      // Вы можете обновить состояние, если нужно
      console.log("Статус обновлен:", action.payload);
    })
    .addCase(updateBusinessTripCahsApprove.rejected, (state, action) => {
      console.error("Ошибка при обновлении статуса:", action.payload);
    });
  // getWarehouseByNumberName
  builder
    .addCase(getWarehouseByNumberName.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getWarehouseByNumberName.fulfilled, (state, action) => {
      state.loading = false;
      state.WarehouseSearch = action.payload;
    })
    .addCase(getWarehouseByNumberName.rejected, (state, action) => {
      console.error("Ошибка при обновлении статуса:", action.payload);
    });
  // getIncomingByNumberName
  builder
    .addCase(getIncomingByWnumCid.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getIncomingByWnumCid.fulfilled, (state, action) => {
      state.loading = false;
      state.IncomingSearch = action.payload;
    })
    .addCase(getIncomingByWnumCid.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // убедитесь, что вы обрабатываете ошибку
    });

  // getIncomingByNumberName
  builder
    .addCase(postIncoming.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(postIncoming.fulfilled, (state, action) => {
      state.loading = false;
      state.IncomingSearch = action.payload;
    })
    .addCase(postIncoming.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // убедитесь, что вы обрабатываете ошибку
    });

  // postWarehoouseStat
  builder
    .addCase(postWarehouseStat.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(postWarehouseStat.fulfilled, (state, action) => {
      state.loading = false;
      state.WareHouseStat = action.payload;
    })
    .addCase(postWarehouseStat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

  // postWarehoouseStat
  builder
    .addCase(getWarehouseDisposal.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(getWarehouseDisposal.fulfilled, (state, action) => {
      state.loading = false;
      state.WarehouseDisposal = action.payload;
    })
    .addCase(getWarehouseDisposal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

  builder
    .addCase(postBid.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(postBid.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
      state.WarehouseDate = [];
      state.WarehouseSearch = [];
      console.log("Данные успешно отправлены:", action.payload);
    })
    .addCase(postBid.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Ошибка при отправке данных:", action.payload);
    });
};
