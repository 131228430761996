import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Date from "../../components/forms/date/Date";
import {
  getBusinessTripCahs,
  getBusinessTripCahsFileById,
  updateBusinessTripCahsApprove,
} from "../../redux/reducers/globalStateQueries/AccountingQueries";
import { Table, Button, Space, Checkbox } from "antd";
import { useTranslation } from "react-i18next";

const BusinessTripCahs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const staff = useSelector(({ globalState }) => globalState.staff);
  const BusinessTripCahsDate = useSelector(
    ({ globalState }) => globalState.BusinessTripCahs
  );
  const departmentIdLSS = useSelector(
    ({ MvdFeuLocalStorageState }) => MvdFeuLocalStorageState.departmentIdLSS
  );
  const user = useSelector(
    ({ MvdFeuLocalStorageState }) => MvdFeuLocalStorageState.user
  );

  const [year, setYear] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleYearChange = (date, dateString) => {
    setYear(dateString);
  };

  const handleSearchClick = () => {
    if (!staff.id || !departmentIdLSS || !year) {
      console.error(t("error.missingFields"));
      return;
    }

    const yearString = typeof year === "string" ? year.split("-")[0] : year;

    setTableData([]);
    setDataLoaded(false);
    dispatch({ type: "CLEAR_BUSINESS_TRIP_CASH_DATA" });

    setLoading(true);

    dispatch(
      getBusinessTripCahs({
        staff_id: staff.id,
        dep: departmentIdLSS,
        year: yearString,
      })
    );
  };

  const handleDownload = (record) => {
    if (!record.approved) {
      console.error(t("error.downloadUnavailable"));
      return;
    }

    if (!staff.id) {
      console.error(t("error.missingStaffId"));
      return;
    }

    dispatch(
      getBusinessTripCahsFileById({
        id: record.id,
        user_id: user.user_id,
      })
    );
  };

  const handleApproveChange = (checked, record) => {
    const updatedData = tableData.map((trip) =>
      trip.key === record.key ? { ...trip, approved: checked } : trip
    );

    setTableData(updatedData);

    dispatch(
      updateBusinessTripCahsApprove({
        id: record.id,
        user_id: user.user_id,
      })
    );
  };

  const columns = [
    {
      title: t("document.start_date"),
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => text || t("notSpecified"),
    },
    {
      title: t("document.end_date"),
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => text || t("notSpecified"),
    },
    {
      title: t("document.payments"),
      dataIndex: "payment",
      key: "payment",
      render: (payment) => (payment ? payment / 100 : t("notSpecified")),
    },
    {
      title: t("document.confirm"),
      key: "approved",
      render: (_, record) => (
        <Checkbox
          checked={record.approved || false}
          onChange={(e) => handleApproveChange(e.target.checked, record)}
          disabled={record.approved}
        >
          {record.approved ? t("document.confirmed") : t("document.confirm")}
        </Checkbox>
      ),
    },
    {
      title: t("document.action"),
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record.approved ? (
            <Button type="link" onClick={() => handleDownload(record)}>
              {t("buttons.download")}
            </Button>
          ) : (
            <span>{t("downloadUnavailable")}</span>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (BusinessTripCahsDate) {
      const data = BusinessTripCahsDate.map((trip, index) => ({
        key: index,
        id: trip.id,
        start_date: trip.start_date,
        end_date: trip.end_date,
        payment: trip.payment,
        doc_id: trip.doc_id,
        approved: trip.approved,
      }));
      setTableData(data);
      setDataLoaded(true);
    }
    setLoading(false);
  }, [BusinessTripCahsDate]);

  return (
    <div>
      <div className="flex justify-center mt-10 mb-10 items-center">
        <Date
          onChange={handleYearChange}
          label={t("document.selectYear") + ":"}
          value={year ? dayjs(year, "YYYY") : null}
          format="YYYY"
          picker="year"
          labelOn="left"
        />
        <Button
          type="primary"
          onClick={handleSearchClick}
          style={{ marginLeft: "10px" }}
        >
          {t("buttons.search")}
        </Button>
      </div>

      {loading ? (
        <p>{t("loadingData")}</p>
      ) : dataLoaded && tableData.length === 0 ? (
        <p>{t("noDataAvailable")}</p>
      ) : tableData.length > 0 ? (
        <div>
          <h2 className="text-center bg-white rounded-lg p-2 text-[20px]">
            {staff.lastname} {staff.firstname} {staff.middlename}
          </h2>
          <Table columns={columns} dataSource={tableData} pagination={false} />
        </div>
      ) : null}
    </div>
  );
};

export default BusinessTripCahs;
