import { numberSchema } from "./Validation";

const documentFilesValidation = async (
  setValidationError = () => {},
  t = () => {},
  formState = {}
) => {
  for (let i = 0; i < formState.files?.length; i++) {
    const isNum = await numberSchema.isValid({
      num: formState.files?.[i]?.doc_number,
    });
    if (!formState.files?.[i]?.fileList) {
      setValidationError({
        files: { [i]: { fileList: true } },
        label: t("validation.getFile"),
      });
      return false;
    } else if (!formState.files?.[i]?.doc_type) {
      setValidationError({
        files: { [i]: { doc_type: true } },
        label: t("validation.getTypeOfFile"),
      });
      return false;
    } else if (!formState.files?.[i]?.doc_number) {
      setValidationError({
        files: { [i]: { doc_number: true } },
        label: t("validation.empty"),
      });
      return false;
    } else if (!isNum) {
      setValidationError({
        files: { [i]: { doc_number: true } },
        label: t("validation.isNumber"),
      });
      return false;
    }
  }
  return true;
};

export { documentFilesValidation };
