import React from "react";
import { FaRegSave } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import Button from "./Button";
import { useTranslation } from "react-i18next";

function SaveAndClearButtons({
  onSave = null,
  onClear = null,
  saveText = null,
  className = "",
}) {
  const { t } = useTranslation();
  return (
    <div className={`flex justify-center mt-[30px] ${className} `}>
      {onSave && (
        <Button
          className="w-[200px]"
          onClick={onSave}
          icon={<FaRegSave className="w-[20px] h-[20px]" />}
        >
          {saveText ? saveText : t("buttons.save")}
        </Button>
      )}
      {onClear && (
        <Button
          className="w-[200px] gap-[5px]"
          onClick={onClear}
          icon={<IoMdRefresh className="w-[20px] h-[20px]" />}
        >
          {t("buttons.clear")}
        </Button>
      )}
    </div>
  );
}

export default SaveAndClearButtons;
