import { Input } from "antd";
import React, { useEffect, useState } from "react";
import LabelCom from "../label/LabelCom";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

function InputCom({
  id = null,
  label = "",
  labelOn = "top",
  error = false,
  errorLabel = null,
  className,
  labelClassName,
  placeholder = "",
  name = null,
  type = "text",
  onlyType = null,
  value = null,
  onChange = null, //(e) => {console.log(e.target.value)},
  between = false,
  disabled = false,
  editable = true,
  defaultValue = "",
  required = false,
  mask = null,
  maskChar = null,
  divClassName = "",
  autocomplete = "off",
  visibilityToggle = false,
  style = {},
  onlyValue = null
}) {
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }, [isError]);

  const handleInput = (e) => {
    const value = e?.target?.value;
    // const isValid = onlyValue ? onlyValue.test(e?.target?.value) : true

    if (onlyType === "text") {
      if (/\d/.test(value)) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      if (onlyValue === "Z99999999") {
        const newValue = value
          .split('')
          .map((char, index) => {
            if (index === 0) {
              return char.replace(/\d/, 'A')
            } else {
              return char.replace(/\D/, '0')
            }
          })
          .join('');
        onChange({
          target: {
            value: newValue.replace(/\d/g, ""),
          },
        });
      } else {
        onChange({
          target: {
            value: value.replace(/\d/g, ""),
          },
        });
      }
    } else if (onlyType === "number") {
      if (!/^\d+$/.test(value)) {
        setIsError(true);
      } else {
        setIsError(false);
      }

      onChange({
        target: {
          value: value.replace(/\D/g, ""),
        },
      });

    } else {
      if (onlyValue === "Z99999999") {
        const newValue = value
          .split('')
          .map((char, index) => {
            if (index === 0) {
              return char.replace(/\d/, 'A')
            } else {
              return char.replace(/\D/, '0')
            }
          })
          .join('');

        onChange({
          target: {
            value: newValue,
          },
        });
      } else {
        onChange({
          target: {
            value: value
          },
        });
      }

    }
  };
  if (mask) {
    return (
      <LabelCom
        labelClassName={labelClassName}
        label={label}
        error={error}
        errorLabel={errorLabel}
        labelOn={labelOn}
        between={between}
        divClassName={divClassName}
        required={required}
      >
        <ReactInputMask
          mask={mask} //
          maskChar={maskChar} //
          id={id}
          className={`w-full min-w-[50px] inputCom ${className}`}
          placeholder={placeholder}
          name={name}
          type={type}
          disabled={disabled}
          defaultValue={defaultValue}
          required={required}
          value={value}
          onChange={onChange}
          autocomplete={autocomplete}
          visibilityToggle={visibilityToggle}
          style={{
            background: error && "rgba(255, 0, 0, 0.216)",
            borderColor: error && "red",
            ...style
          }}
        >
          {(inputProps) => <Input {...inputProps} />}
        </ReactInputMask>
      </LabelCom>
    );
  }

  if (!editable) {
    return (
      <LabelCom
        labelClassName={labelClassName}
        label={label}
        labelOn={labelOn}
        between={between}
        divClassName={divClassName}
        required={required}
      >
        <Input
          className={`w-full min-w-[50px] inputCom ${className}`}
          placeholder={placeholder}
          name={name}
          style={{
            background: error && "rgba(255, 0, 0, 0.216)",
            borderColor: error && "red",
            ...style
          }}
          // onlyType={onlyType}
          // hint={hint}
          type={type}
          disabled={disabled}
          value={value} //
          required={required}
          autocomplete={autocomplete}
          visibilityToggle={visibilityToggle}
        />
      </LabelCom>
    );
  }

  if (onChange) {
    return (
      <LabelCom
        labelClassName={labelClassName}
        label={label}
        error={isError || error}
        errorLabel={
          isError
            ? onlyType !== "number"
              ? t("validation.noDigitsText")
              : onlyType === "text" && t("validation.onlyDigitsText")
            : errorLabel
        }
        labelOn={labelOn}
        between={between}
        divClassName={divClassName}
        required={required}
      >
        <Input
          id={id}
          className={`w-full min-w-[50px] inputCom ${className}`}
          style={{
            background: (isError || error) && "rgba(255, 0, 0, 0.216)",
            borderColor: (isError || error) && "red",
            ...style
          }}
          placeholder={placeholder}
          name={name}
          type={type}
          disabled={disabled}
          value={value}
          onInput={handleInput}
          defaultValue={defaultValue}
          required={required}
          autocomplete={autocomplete}
          visibilityToggle={visibilityToggle}
        />
      </LabelCom>
    );
  }

  return (
    <LabelCom
      labelClassName={labelClassName}
      label={label}
      error={error}
      errorLabel={errorLabel}
      labelOn={labelOn}
      between={between}
      divClassName={divClassName}
      required={required}
    >
      <Input
        id={id}
        classxName={`w-full min-w-[50px] inputCom ${className}`}
        placeholder={placeholder}
        name={name} //
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        required={required}
        autoСomplete={autocomplete}
        visibilityToggle={visibilityToggle}
        style={style}
      />
    </LabelCom>
  );
}

export default InputCom;
