import { destroyToken } from "../../../../utils/Token";
import {
  checkLogin,
  loginUser,
  otpConfirm,
  otpGenerate,
  refreshToken,
  resetPassword,
} from "../../globalStateQueries/AuthQueries";

export const AuthExtraReducers = (builder) => {
  // check login
  builder
    .addCase(checkLogin.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(checkLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.loginData = action.payload?.body;
    })
    .addCase(checkLogin.rejected, (state, action) => {
      state.loading = false;
    });

  // login user
  builder
    .addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
    });

  // reset password
  builder
    .addCase(otpGenerate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(otpGenerate.fulfilled, (state, action) => {
      state.loading = false;
      state.otp_url = action.payload?.otp_url;
    })
    .addCase(otpGenerate.rejected, (state, action) => {
      state.loading = false;
    });

  // reset password
  builder
    .addCase(otpConfirm.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(otpConfirm.fulfilled, (state, action) => {
      state.loading = false;
      state.otpModal = { open: false, details: null };
    })
    .addCase(otpConfirm.rejected, (state, action) => {
      state.loading = false;
    });

  // reset password
  builder
    .addCase(refreshToken.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(refreshToken.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(refreshToken.rejected, (state, action) => {
      state.loading = false;
    });

  // reset password
  builder
    .addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      destroyToken();
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });
};
