import {
  getDocById,
  getFileById,
  putFileById,
} from "../../globalStateQueries/StorageQueries";

export const StorageExtraReducers = (builder) => {
  // get doc by id
  builder
    .addCase(getDocById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDocById.fulfilled, (state, action) => {
      state.loading = false;
      state.docData = action.payload;
    })
    .addCase(getDocById.rejected, (state, action) => {
      state.loading = false;
    });

  // get file by id
  builder
    .addCase(getFileById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getFileById.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(getFileById.rejected, (state, action) => {
      state.loading = false;
    });

  // get file by id
  builder
    .addCase(putFileById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(putFileById.fulfilled, (state, action) => {
      state.loading = false;
      state.putDocModal = { open: false, details: null };
    })
    .addCase(putFileById.rejected, (state, action) => {
      state.loading = false;
    });
};
