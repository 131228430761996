import { AccountingExtraReducers } from "./globalStateExtraReducers/AccountingExtraReducers";
import { AdminExtraReducers } from "./globalStateExtraReducers/AdminExtraReducers";
import { AuthExtraReducers } from "./globalStateExtraReducers/AuthExtraReducers";
import { CommonExtraReducers } from "./globalStateExtraReducers/CommonExtraReducers";
import { HrExtraReducers } from "./globalStateExtraReducers/HrExtraReducers";
import { PaymentExtraReducers } from "./globalStateExtraReducers/PaymentExtraReducers";
import { PensionExtraReducers } from "./globalStateExtraReducers/PensionExtraReducers";
import { StorageExtraReducers } from "./globalStateExtraReducers/StorageExtraReducers";

export function globalStateExtraReducers(builder) {
  ////////    Swagger ACCOUNTING    ////////
  AccountingExtraReducers(builder);

  ////////    Swagger ADMIN    ////////
  AdminExtraReducers(builder);

  ////////    Swagger AUTH    ////////
  AuthExtraReducers(builder);

  ////////    Swagger COMMON    ////////
  CommonExtraReducers(builder);

  ////////    Swagger HR    ////////
  HrExtraReducers(builder);

  ////////    Swagger PAYMENT    ////////
  PaymentExtraReducers(builder);

  ////////    Swagger PENSION    ////////
  PensionExtraReducers(builder);

  ////////    Swagger STORAGE    ////////
  StorageExtraReducers(builder);
}
