import { defaultUnitsObj } from "../../../../constanst/const";
import { setCurrentYear } from "../../../../utils/globalFunction";
import {
  getAdditionalPaymentsOption,
  getCalendarYear,
  getCountries,
  getDeceasedStaffGroups,
  getDepartmentOption,
  getDisabledStaffGroups,
  getDisciplinaryAction,
  getDismissalTypes,
  getDocumentType,
  getGenderOption,
  getJobtitleOption,
  getMarkerOption,
  getModules,
  getMonthDate,
  getNamenclatura,
  getPensionAwards,
  getPensionTypes,
  getRankOption,
  getRegion,
  getRetentionsOption,
  getStaffFinancialAid,
  getStaffStatus,
  getTripDailyPayOptions,
  getUnits,
  getVacationOption,
} from "../../globalStateQueries/CommonQueries";
import {
  getDepartmentBusinessTrip,
  getStaffBusinessTrip,
  upsertBusinessTrip,
} from "../../globalStateQueries/HrQueries";

export const CommonExtraReducers = (builder) => {
  // get modules
  builder
    .addCase(getModules.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getModules.fulfilled, (state, action) => {
      state.loading = false;
      state.modules = action.payload;
    })
    .addCase(getModules.rejected, (state, action) => {
      state.loading = false;
    });

  // get department option
  builder
    .addCase(getDepartmentOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDepartmentOption.fulfilled, (state, action) => {
      state.loading = false;
      state.departments = action.payload;
      state.departmentOption = action.payload;
    })
    .addCase(getDepartmentOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get rank option
  builder
    .addCase(getRankOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRankOption.fulfilled, (state, action) => {
      state.loading = false;
      state.rankOption = action.payload;
    })
    .addCase(getRankOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get rank option
  builder
    .addCase(getGenderOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getGenderOption.fulfilled, (state, action) => {
      state.loading = false;
      state.genderOption = action.payload;
    })
    .addCase(getGenderOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get marker option
  builder
    .addCase(getMarkerOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getMarkerOption.fulfilled, (state, action) => {
      state.loading = false;
      state.markerOption = action.payload;
    })
    .addCase(getMarkerOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get month date
  builder
    .addCase(getMonthDate.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getMonthDate.fulfilled, (state, action) => {
      state.loading = false;
      state.monthDate = action.payload;
      if (action.payload?.current_date) {
        setCurrentYear(action.payload?.current_date?.split("-")[0]);
      } else {
        setCurrentYear(action.payload?.first_date?.split("-")[0]);
      }
    })
    .addCase(getMonthDate.rejected, (state, action) => {
      state.loading = false;
    });

  // get retentions option
  builder
    .addCase(getRetentionsOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRetentionsOption.fulfilled, (state, action) => {
      state.loading = false;
      state.retentionsOption = action.payload;
    })
    .addCase(getRetentionsOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get additional payment
  builder
    .addCase(getAdditionalPaymentsOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAdditionalPaymentsOption.fulfilled, (state, action) => {
      state.loading = false;
      state.additionalPaymentOption = action.payload;
    })
    .addCase(getAdditionalPaymentsOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get staff status
  builder
    .addCase(getStaffStatus.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.staffStatus = action.payload;
    })
    .addCase(getStaffStatus.rejected, (state, action) => {
      state.loading = false;
    });

  // get dismissal types
  builder
    .addCase(getDismissalTypes.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDismissalTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.dismissalTypes = action.payload;
    })
    .addCase(getDismissalTypes.rejected, (state, action) => {
      state.loading = false;
    });

  // get calendar year
  builder
    .addCase(getCalendarYear.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCalendarYear.fulfilled, (state, action) => {
      state.loading = false;
      state.calendarYear = action.payload;
    })
    .addCase(getCalendarYear.rejected, (state, action) => {
      state.loading = false;
    });

  // get disciplinary action
  builder
    .addCase(getDisciplinaryAction.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDisciplinaryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.disciplinaryActionOption = action.payload;
    })
    .addCase(getDisciplinaryAction.rejected, (state, action) => {
      state.loading = false;
    });

  // get pension types
  builder
    .addCase(getPensionTypes.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionTypes = action.payload;
    })
    .addCase(getPensionTypes.rejected, (state, action) => {
      state.loading = false;
    });

  // get document type
  builder
    .addCase(getDocumentType.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDocumentType.fulfilled, (state, action) => {
      state.loading = false;
      state.documentTypes = action.payload;
    })
    .addCase(getDocumentType.rejected, (state, action) => {
      state.loading = false;
    });

  // get deceased staff groups
  builder
    .addCase(getDeceasedStaffGroups.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDeceasedStaffGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.deceasedStaffGroups = action.payload;
    })
    .addCase(getDeceasedStaffGroups.rejected, (state, action) => {
      state.loading = false;
    });

  // get disabled staff groups
  builder
    .addCase(getDisabledStaffGroups.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDisabledStaffGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.disabledStaffGroups = action.payload;
    })
    .addCase(getDisabledStaffGroups.rejected, (state, action) => {
      state.loading = false;
    });

  // get staff financial aid
  builder
    .addCase(getStaffFinancialAid.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffFinancialAid.fulfilled, (state, action) => {
      state.loading = false;
      state.staffFinancialAid = action.payload;
    })
    .addCase(getStaffFinancialAid.rejected, (state, action) => {
      state.loading = false;
    });

  // get vacation types
  builder
    .addCase(getVacationOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getVacationOption.fulfilled, (state, action) => {
      state.loading = false;
      state.vacationOption = action.payload;
    })
    .addCase(getVacationOption.rejected, (state, action) => {
      state.loading = false;
    });

  // upsert business trip
  builder
    .addCase(upsertBusinessTrip.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertBusinessTrip.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertBusinessTrip.rejected, (state, action) => {
      state.loading = false;
    });

  // get department business trip
  builder
    .addCase(getDepartmentBusinessTrip.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getDepartmentBusinessTrip.fulfilled, (state, action) => {
      state.loading = false;
      state.departmentBusinessTrip = action.payload;
    })
    .addCase(getDepartmentBusinessTrip.rejected, (state, action) => {
      state.loading = false;
    });

  // get staff business trip
  builder
    .addCase(getStaffBusinessTrip.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getStaffBusinessTrip.fulfilled, (state, action) => {
      state.loading = false;
      state.staffBusinessTrip = action.payload;
    })
    .addCase(getStaffBusinessTrip.rejected, (state, action) => {
      state.loading = false;
    });

  // get trip daily pay
  builder
    .addCase(getTripDailyPayOptions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getTripDailyPayOptions.fulfilled, (state, action) => {
      state.loading = false;
      state.tripDailyPayOptions = action.payload;
    })
    .addCase(getTripDailyPayOptions.rejected, (state, action) => {
      state.loading = false;
    });

  // get jobtitle option
  builder
    .addCase(getJobtitleOption.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getJobtitleOption.fulfilled, (state, action) => {
      state.loading = false;
      state.jobtitleOption = action.payload;
    })
    .addCase(getJobtitleOption.rejected, (state, action) => {
      state.loading = false;
    });

  // get countries
  builder
    .addCase(getCountries.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.loading = false;
      state.countries = action.payload;
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.loading = false;
    });

  // get regions
  builder
    .addCase(getRegion.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getRegion.fulfilled, (state, action) => {
      state.loading = false;
      state.region = action.payload;
    })
    .addCase(getRegion.rejected, (state, action) => {
      state.loading = false;
    });

  // get pension awards
  builder
    .addCase(getPensionAwards.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionAwards.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionAwards = action.payload;
    })
    .addCase(getPensionAwards.rejected, (state, action) => {
      state.loading = false;
    });

  builder
    .addCase(getNamenclatura.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getNamenclatura.fulfilled, (state, action) => {
      state.loading = false;
      state.Namenclatura = action.payload;
      if (Array.isArray(action.payload)) {
        const Obj = {};
        action.payload?.map((el) => {
          Obj[el.id] = { ...el };
          return el;
        });
        state.NamenclaturaObj = { ...Obj };
      }
    })
    .addCase(getNamenclatura.rejected, (state, action) => {
      state.loading = false;
    });
  // units
  builder
    .addCase(getUnits.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getUnits.fulfilled, (state, action) => {
      state.loading = false;
      state.Units = action.payload;
      if (Array.isArray(action.payload)) {
        const Obj = { ...defaultUnitsObj };
        action.payload?.map((el) => {
          Obj[el.id] = { name: el.name };
          return el;
        });
        state.UnitsObj = { ...Obj };
      }
    })
    .addCase(getUnits.rejected, (state, action) => {
      state.loading = false;
    });
};
