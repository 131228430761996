import {
  getPensionAccountingById,
  getPensioners,
  upsertPensionAccounting,
} from "../../globalStateQueries/PesionQueries";

export const PensionExtraReducers = (builder) => {
  // upsert pension acounting
  builder
    .addCase(upsertPensionAccounting.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(upsertPensionAccounting.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(upsertPensionAccounting.rejected, (state, action) => {
      state.loading = false;
    });

  // get pension accounting by id
  builder
    .addCase(getPensionAccountingById.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensionAccountingById.fulfilled, (state, action) => {
      state.loading = false;
      state.pensionAccountings = action.payload;
    })
    .addCase(getPensionAccountingById.rejected, (state, action) => {
      state.loading = false;
    });

  // get pension accounting by id
  builder
    .addCase(getPensioners.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getPensioners.fulfilled, (state, action) => {
      state.loading = false;
      state.pensioners = action.payload;
    })
    .addCase(getPensioners.rejected, (state, action) => {
      state.loading = false;
    });
};
