import React from "react";

function TitleDoc({ children, className = "" }) {
  return (
    <div className=" w-full titleDoc">
      <h3
        className={`text-[24px] text-center font-bold leading-[74px] ${className}`}
      >
        {children}
      </h3>
    </div>
  );
}

export default TitleDoc;
