import {
  object,
  string,
  number,
  // date,
  //  InferType
} from "yup";

// const retentionSchema = object().shape({
// //   retention: object({
// //     value: string().required("Required"),
// //   }),
// //   start_date: object({
// //     dateStr: string().required("Required"),
// //     date: object(),
// //   }),
// //   end_date: object({
// //     dateStr: string().required("Required"),
// //     date: object(),
// //   }),
//   amount: number('Is not number').required("Required"),
// });

export const numberSchema = object().shape({
  num: number().required(),
});

export const tinSchema = object().shape({
  tin: string().min(9).max(9).required(),
});
